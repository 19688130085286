/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.HomeAboveFold {
  overflow: hidden;
  position: relative;
  background: #ff486b;
  min-height: 63rem;
}
@media (max-width: 767px) {
  .HomeAboveFold {
    font-size: 1.2rem;
  }
}
.HomeAboveFold .maxWidthContentWrapper {
  padding: 8rem;
}
@media (max-width: 767px) {
  .HomeAboveFold .maxWidthContentWrapper {
    padding: 0;
  }
}
.HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__title {
  color: #fff;
  font-weight: 600;
  font-size: 4rem;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 6.5rem;
  max-width: 75rem;
  margin: 0 auto;
  position: relative;
  z-index: 100;
}
@media (max-width: 767px) {
  .HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__title {
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0;
    margin-top: 14rem;
  }
}
.HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__subtitle {
  color: #fff;
  font-weight: 600;
  font-size: 1.8rem;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: 55.2rem;
  margin: 3rem auto 0;
  position: relative;
  z-index: 100;
}
@media (max-width: 767px) {
  .HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__subtitle {
    font-size: 1.4em;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.HomeAboveFold .maxWidthContentWrapper .trustpilotTopBox {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  color: #191919;
  text-align: center;
  margin-top: 4rem;
}
.HomeAboveFold .maxWidthContentWrapper .trustpilotTopBox .scoreName {
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  padding-right: 2rem;
  color: #fff;
}
.HomeAboveFold .maxWidthContentWrapper .trustpilotTopBox .summary {
  margin-top: 1rem;
  margin-left: 2rem;
  display: inline-block;
  color: #fff;
}
.HomeAboveFold .maxWidthContentWrapper .trustpilotTopBox .stars {
  height: 2rem;
  display: inline-block;
  margin-top: -0.4rem;
}
.HomeAboveFold .maxWidthContentWrapper .trustpilotTopBox .logo {
  height: 2rem;
  display: inline-block;
  margin-top: -0.4rem;
}
.HomeAboveFold .maxWidthContentWrapper .trustpilot-widget {
  margin-top: 4rem;
}
.HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__buttons {
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: 75rem;
  margin: 0 auto;
  text-align: center;
  margin-top: 4rem;
  position: relative;
  z-index: 300;
}
.HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__buttons .HomeAboveFold__watchVideo {
  margin-right: 7rem;
}
@media (max-width: 767px) {
  .HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__buttons .HomeAboveFold__watchVideo {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__buttons .startCoding {
    display: none;
  }
}
.HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__desktop .HomeAboveFold__parentsClouds {
  position: absolute;
  left: 0;
  top: 9rem;
  width: 100%;
}
.HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__desktop .HomeAboveFold__parentsLeftSide {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 100;
}
.HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__desktop .HomeAboveFold__parentsRightSide {
  position: absolute;
  right: 0;
  bottom: 0;
}
.HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__desktop .HomeAboveFold__parentsLandingTop {
  position: absolute;
  top: -2rem;
  left: 22rem;
  width: 14rem;
}
@media (max-width: 767px) {
  .HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__desktop {
    display: none;
  }
}
.HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__mobile {
  display: none;
}
.HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__mobile .parentsLandingMobileTop {
  position: absolute;
  top: 0;
  left: 0;
}
.HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__mobile .parentsLandingMobileBottom {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 767px) {
  .HomeAboveFold .maxWidthContentWrapper .HomeAboveFold__mobile {
    display: block;
  }
}
