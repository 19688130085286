/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Partners {
  text-align: center;
  padding: 0 4rem 4rem;
  background: #f3365a;
  display: none;
}
.Partners__title {
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  padding-top: 3rem;
}
.Partners__logos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-end;
  max-width: 68rem;
  margin: auto;
}
.Partners__image {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.Partners__image-container {
  width: 33%;
}
@media (max-width: 991px) {
  .Partners__image-container {
    width: 50%;
  }
}
