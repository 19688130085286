/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.pageContainer.blackfriday .InThePress {
  display: none;
}
.InThePress {
  width: 100%;
  height: 50rem;
  background: #fff;
}
@media (max-width: 767px) {
  .InThePress {
    display: none;
  }
}
.InThePress .background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: bottom;
  position: relative;
  background-image: url('/graphics/in-the-press.png');
}
.InThePress .quote {
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 6rem;
  left: 10rem;
  font-size: 3.8rem;
  width: 45rem;
  font-style: italic;
}
@media (max-width: 767px) {
  .InThePress .quote {
    width: 100%;
    top: 2rem;
    left: 0;
    padding: 3rem;
    font-size: 3rem;
  }
}
.InThePress .logo {
  position: absolute;
  bottom: 12rem;
  left: 10rem;
}
.InThePress .logo img {
  width: 15rem;
}
@media (max-width: 767px) {
  .InThePress .logo {
    bottom: 2rem;
    left: 0;
  }
}
