/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 991px) {
  .ParentsLandingPage {
    margin-top: -5rem;
  }
}
.ParentsLandingPage .HowItWorks {
  background-color: #f9f6ef;
}
.ParentsLandingPage .importanceOfCode {
  background: url('/graphics/landing/why-code-important-background.svg') no-repeat #745a9e;
  background-size: cover;
  background-position: bottom;
  min-height: 55rem;
}
@media (max-width: 991px) {
  .ParentsLandingPage .importanceOfCode {
    background-size: contain;
  }
}
.ParentsLandingPage .importanceOfCode .maxWidthContentWrapper {
  padding: 8rem;
}
@media (max-width: 767px) {
  .ParentsLandingPage .importanceOfCode .maxWidthContentWrapper {
    padding: 3rem;
  }
}
.ParentsLandingPage .importanceOfCode .maxWidthContentWrapper > .title {
  color: #fff;
  font-weight: 600;
  font-size: 4rem;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: 75rem;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .ParentsLandingPage .importanceOfCode .maxWidthContentWrapper > .title {
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.ParentsLandingPage .importanceOfCode .maxWidthContentWrapper > .subtitle {
  color: #fff;
  font-weight: 600;
  font-size: 2.2rem;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: 50rem;
  margin: 3rem auto 0;
}
@media (max-width: 767px) {
  .ParentsLandingPage .importanceOfCode .maxWidthContentWrapper > .subtitle {
    font-size: 1.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.ParentsLandingPage .importanceOfCode .maxWidthContentWrapper .buttons {
  margin-top: 6rem;
  text-align: center;
}
.ParentsLandingPage .whatCustomersSay {
  background: #f9f6ef;
}
.ParentsLandingPage .whatCustomersSay > .title {
  text-align: center;
  font-size: 3.2rem;
  font-weight: bold;
  color: #745a9e;
  padding-top: 4rem;
}
.ParentsLandingPage .whatCustomersSay .animationFrame {
  display: block;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .ParentsLandingPage .whatCustomersSay .animationFrame .desktop {
    display: none;
  }
}
.ParentsLandingPage .whatCustomersSay .animationFrame .mobile {
  display: none;
}
@media (max-width: 767px) {
  .ParentsLandingPage .whatCustomersSay .animationFrame .mobile {
    display: block;
  }
}
.ParentsLandingPage .PaymentPlans {
  background: #f2eee5;
}
.ParentsLandingPage .PaymentPlans .bottomInfo {
  background: #f9f6ef;
}
.ParentsLandingPage .Introduction {
  position: relative;
  width: 100%;
  padding: 0 16rem;
  background: #f9f6ef;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .ParentsLandingPage .Introduction {
    padding: 2rem 4rem;
  }
}
.ParentsLandingPage .Introduction .topCloudImageFrame {
  position: absolute;
  top: 5rem;
  left: -13rem;
}
@media (max-width: 767px) {
  .ParentsLandingPage .Introduction .topCloudImageFrame {
    display: none;
  }
}
.ParentsLandingPage .Introduction .bottomCloudImageFrame {
  position: absolute;
  bottom: 5rem;
  right: -11rem;
}
@media (max-width: 767px) {
  .ParentsLandingPage .Introduction .bottomCloudImageFrame {
    display: none;
  }
}
.ParentsLandingPage .Introduction .row {
  margin: 11rem 0;
  z-index: 5;
  position: relative;
}
.ParentsLandingPage .Introduction h2 {
  font-weight: 600;
  color: #745a9e;
  font-size: 5.2em;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .ParentsLandingPage .Introduction h2 {
    text-align: center;
    font-size: 3.8em;
  }
}
.ParentsLandingPage .Introduction .description {
  color: #534468;
  font-size: 2.2em;
  font-weight: 600;
}
@media (max-width: 767px) {
  .ParentsLandingPage .Introduction .description {
    text-align: center;
    font-size: 1.6em;
  }
}
.ParentsLandingPage .Introduction .textFrameLeft,
.ParentsLandingPage .Introduction .textFrameRight {
  width: 40%;
  margin-top: -7rem;
}
@media (max-width: 767px) {
  .ParentsLandingPage .Introduction .textFrameLeft,
  .ParentsLandingPage .Introduction .textFrameRight {
    width: 100%;
    float: none;
    margin-top: 3rem;
  }
}
.ParentsLandingPage .Introduction .imageFrameLeft,
.ParentsLandingPage .Introduction .imageFrameRight {
  width: 40%;
}
.ParentsLandingPage .Introduction .imageFrameLeft img,
.ParentsLandingPage .Introduction .imageFrameRight img {
  border-radius: 3rem;
}
@media (max-width: 767px) {
  .ParentsLandingPage .Introduction .imageFrameLeft,
  .ParentsLandingPage .Introduction .imageFrameRight {
    width: 100%;
    float: none;
  }
}
.ParentsLandingPage .Introduction .textFrameLeft {
  float: left;
}
.ParentsLandingPage .Introduction .textFrameRight {
  float: right;
}
.ParentsLandingPage .Introduction .imageFrameLeft {
  float: left;
}
.ParentsLandingPage .Introduction .imageFrameRight {
  float: right;
}
.ParentsLandingPage .greyCarouselBackground {
  background: #f9f6ef;
}
.ParentsLandingPage .arrowAnimation {
  position: relative;
  bottom: 4rem;
  left: 50%;
  margin-left: -2.5rem;
  background: #ffdc4a;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  -webkit-animation: downarrow 1.75s infinite alternate ease-in-out;
  animation: downarrow 1.75s infinite alternate ease-in-out;
}
.ParentsLandingPage .arrowAnimation .arrow {
  border: solid white;
  border-width: 0 0.5rem 0.5rem 0;
  display: inline-block;
  padding: 0.7rem;
  margin-top: 1.3rem;
  margin-left: 1.3rem;
}
@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0) rotate(45deg);
    transform: translateY(0) rotate(45deg);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: translateY(3rem) rotate(45deg);
    transform: translateY(3rem) rotate(45deg);
    opacity: 1;
  }
}
